import * as LDClient from 'launchdarkly-js-client-sdk'
import { PropsWithChildren } from 'react'

import { clientEnv } from '../env.js'
import { captureError } from '../error-reporting.js'
import { logger } from '../logger/logger.js'
import { featureFlaggingContext } from './context.js'

const timeoutSeconds = 2

const createClient = async () => {
  if (!clientEnv.LAUNCH_DARKLY_CLIENT_ID) {
    logger.info('No LaunchDarkly client ID found, skipping client initialization')
    return undefined
  }

  const { flags, context } = window.ldData

  if (!context) {
    captureError(new Error('No LaunchDarkly context found, skipping client initialization'))
    return undefined
  }

  const client = LDClient.initialize(clientEnv.LAUNCH_DARKLY_CLIENT_ID, context, { bootstrap: flags })
  await client.waitForInitialization(timeoutSeconds)

  return client
}

export const createFeatureFlaggingClientProvider = async () => {
  const { flags, context } = window.ldData

  const client = await createClient()

  return ({ children }: PropsWithChildren) => (
    <featureFlaggingContext.Provider value={{ flags, context, client }}>{children}</featureFlaggingContext.Provider>
  )
}
