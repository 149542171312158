/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { Intercom } from '@intercom/messenger-js-sdk'
import { RemixBrowser } from '@remix-run/react'
import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'

import { captureError, startErrorReporter } from './lib/error-reporting.js'
import { createFeatureFlaggingClientProvider } from './lib/feature-flagging/client.jsx'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { clientEnv } from './lib/env.js'
import { logger } from './lib/logger/logger.js'

let hasLoaded = false

const IntercomStartup = () => {
  useEffect(() => {
    if (!hasLoaded) {
      Intercom({
        app_id: 'vwwb0dzq',
      })
      hasLoaded = true
    }
  }, [])

  return null
}

if (clientEnv.BUGSNAG_API_KEY) {
  BugsnagPerformance.start({
    apiKey: clientEnv.BUGSNAG_API_KEY,
    releaseStage: clientEnv.HABITAT,
    appVersion: clientEnv.APP_VERSION,
  })
} else {
  logger.info('BUGSNAG_API_KEY not set, skipping error reporting')
}

const capturedErrorMap = new Map()

const deepestElementsToSkipSnag = ['at html', 'at button', 'at video', 'at a', 'at link', 'link']

startErrorReporter()

const initialize = async () => {
  const FeatureFlaggingClientProvider = await createFeatureFlaggingClientProvider()

  startTransition(() => {
    hydrateRoot(
      document.getElementById('root')!,
      <StrictMode>
        <FeatureFlaggingClientProvider>
          <IntercomStartup />
          <RemixBrowser />
        </FeatureFlaggingClientProvider>
      </StrictMode>,
      {
        onRecoverableError(error, errorInfo) {
          const errString = String(error)
          if (capturedErrorMap.has(errString)) {
            return
          }

          // extract first line of component stack to determine deepest element
          const deepestElement = (errorInfo.componentStack ?? '').trim().split('\n')[0] ?? ''

          captureError(new Error(`${error} - ${errorInfo.componentStack}`), {
            skipSnag: deepestElementsToSkipSnag.includes(deepestElement),
            onError(event) {
              event.groupingHash = `hydrate#onRecoverableError - ${errString}`
            },
          })
          capturedErrorMap.set(errString, true)
        },
      },
    )
  })

  window.dispatchEvent(new Event('remix-app-loaded'))
}

initialize()
